{% import 'macros/helpers' as helpers %}
<template>
    <div v-show="popupOpen" id="popup">
        <div id="popup-scrim" @click="closePopup"></div>
        <div class="capper">
            <div class="capper-padded">
                <div id="popup-inner">
                    <div @click="closePopup" class="close-button"></div>
                    <div class="content-inner">
                        <div class="content-inner2">
                            <div class="rte no-max-width">
                                <div class="flex-lr">
                                    <div class="flex-lr-l">
                                        <img v-if="obj.bg" :src="obj.bg"/>
                                        <img v-else src="@/assets/images/global/film-no-poster.svg" />
                                    </div>
                                    <div class="flex-lr-r">
                                        <h3>{{obj.title}}</h3>
                                        <div v-if="obj.genre" class="genre"><p>{{ obj.genre }}</p></div>
                                        <div  v-if="obj.principles" class="principle row">
                                            <div class="principle" v-for="(item, index) in obj.principles" :key="`item-${index}-${Math.random()}`" >
                                                <p>{{item.position}}: {{item.name}}</p>
                                            </div>
                                        </div>
                                        <div class="btns">
                                            <div v-if="obj.ctaLink" class="link">
                                                <a :href="obj.ctaLink" target="_blank">
                                                    <div class="btn fullWidth">
                                                        <span>{{obj.ctaLabel}}</span>
                                                    </div>
                                                </a>
                                            </div>
                                            <div v-if="obj.cta2Link" class="link">
                                                <a :href="obj.cta2Link" target="_blank">
                                                    <div class="btn fullWidth">
                                                        <span>{{obj.cta2Label}}</span>
                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="info row" v-html="obj.moreInfo"></div>

                                <div v-if="obj.quote?.quote" class="quote-outer row">
                                    <div class="quote"  v-html='`<p>“${obj.quote.quote}”</p>`'></div>
                                    <div v-if="obj.quote.attribute" class="attribute" ><small v-html='`-${obj.quote.attribute}`'></small></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
//state
import { mapState } from 'vuex'
import gh from '@/helpers/gh'
import gsap, {Power3} from 'gsap'
export default {
    name: "PopupComponent",
    data() {
        return {
            window:window,
            json:null,
            isTransition: false,
        }
    },
    computed: {
        ...mapState({
            popupOpen: state => state.popupOpen,
            obj: state => state.popupObject,
        }),

    },
    watch: {
        popupOpen() {
            if (this.popupOpen) {
                this.reveal()
            } else {
                this.unreveal()
            }
        }
    },
    mounted() {
        window.addEventListener('keyup', this.detectEscapeKey)
    },
    methods: {
        detectEscapeKey(e) {
            if (e.keyCode === 27) {
                this.closePopup()
            }
        },
        reveal() {
            if (this.isTransition === false) {
                this.isTransition = true
                gh.enableBodyScroll(false)
                let tl = gsap.timeline({});
                tl.fromTo('#popup-inner', 0.35,
                    {
                        //left: '100%',
                        opacity: 0
                    },
                    {
                        //left: '0%',
                        opacity:1,
                        ease: Power3.easeOut,
                        onComplete: this.reveal2
                    },
                    0
                )
            }
        },
        reveal2() {
            this.isTransition = false
            const newUrl = `${window.location.origin}/storytelling/${this.obj.slug}`;
            window.history.pushState({ path: newUrl }, '', newUrl);
        },
        closePopup() {
            if (this.isTransition === false) {
                this.isTransition = true
                this.unreveal()
            }
        },
        unreveal() {
            let tl = gsap.timeline({});
            tl.fromTo('#popup-inner', 0.25,
                {
                    //left: 0,
                    opacity:1
                },
                {
                    //left: '-100%',
                    opacity:0,
                    ease: Power3.easeIn,
                    onComplete:this.unreveal2
                },
                0
            )

        },
        unreveal2() {
            gh.enableBodyScroll(true)
            this.isTransition = false
            this.$store.commit('closePopup')
            const newUrl = `${window.location.origin}/storytelling/`;
            window.history.pushState({ path: newUrl }, '', newUrl);
        },
    }
}
</script>
