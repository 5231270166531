<template>
    <section v-if="json" class="page-home" >
        <!--<BlueCenterLogo :nameRight="true" ></BlueCenterLogo>-->

                <div class="flex-center">
                    <div class="flex-center-inner">
                        <div class="capper">
                            <div class="capper-padded-2x">
                        <div class="home-video">
                            <router-link
                                to="/what-we-believe"
                            >
                                <video  autoplay muted webkit-playsinline="true" playsinline="true">
                                    <source src="@/assets/home-video.mp4" type="video/mp4">
                                </video>
                            </router-link>
                        </div>

                        <div class="home-bottom" :class="(revealReady) ? 'opacity1' : 'opacity0'" >
                            <div class="home-bottom-inner">
                                <div class="rte home-content">
                                    <p class="txt" v-html="json.body"></p>
                                    <!--
                                    <div class="logo-holder">
                                        <div class="logo-holder2">
                                            <img src="/assets/site/images/global/tt-blue.png">
                                        </div>
                                    </div>
                                    -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
//import BlueCenterLogo from '@/components/pieces/BlueCenterLogo'
import gsap, {Power3} from 'gsap'
import { SplitText} from 'gsap/SplitText'
import { mapState } from 'vuex'
gsap.registerPlugin(SplitText)
export default {
    name: "HomePage",
    components: {
        //BlueCenterLogo
    },
    data () {
        return {
            window: window,
            json: null,
            inPanelTransition: false,
            revealReady:false
        }
    },
    created () {

    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition
        }),
    },
    mounted () {
        axios.get((`${window.config.apiroot}home${window.config.apiext}`))
            .then((res) => {
                this.json = res.data

                if (this.didInitialSiteTransition) {
                    if (!this.inPanelTransition) {
                        setTimeout(this.reveal,10)
                    }
                }

            }).catch(() => {
        })
    },
    watch: {
        didInitialSiteTransition() {
            if (!this.inPanelTransition) {
                this.reveal()
            }

        }
    },
    methods: {
        reveal: async function() {
            this.inPanelTransition = true

            await new SplitText('.page-home .txt', {
                type: 'words, chars',
                charsClass: 'txt-split'
            });

            this.reveal2()
        },
        reveal2 () {

            let tl = gsap.timeline({
                delay:(this.revealReady) ? 0 : 0.25
            });

            tl.fromTo('.page-home .txt .txt-split',
                {
                    opacity: 0,
                },
                {
                    duration: 0.35,
                    opacity: 1,
                    ease: Power3.easeOut,
                    stagger: 0.007,
                    onStart:this.revealReadyMethod
                },
                2
            )
        },

        revealReadyMethod () {
            this.revealReady = true
        }
    }
}
</script>
