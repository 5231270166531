<template>
    <div title="Scroll Down" id="downarrow-container"
         :class="[`route-type-${routeType}`,`header-theme-${headerTheme}`, (isSmallHeader) ? 'scrolled' : 'top']"
    >
        <span class="material-icons">arrow_circle_down</span>
    </div>
</template>
<script>
//state
import { mapState } from 'vuex'
export default {
    name: "DownArrow",
    components: {

    },
    data() {
        return {
            window:window,
            isSmallHeader:false,
            routeType:'',
            headerTheme:null
        }
    },
    computed: {
        ...mapState({
            smallHeader: state => state.smallHeader,
        })
    },
    watch: {
        smallHeader() {
            this.isSmallHeader = this.smallHeader
        },
        $route (to){
            this.routeType = to.meta.type
            this.headerTheme = to.meta.headerTheme
        },
    },
    mounted() {

    },

    methods: {

    }
}
</script>
