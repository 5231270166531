<template>
    <div v-if="subnavPrev && subnavNext" class="group block-subnav" data-aos="custom-fade-in">
        <div class="capper">
            <div class="capper-padded-4x">
                <div class="visionaries-nav-bar">
                    <router-link :to="subnavPrev?.path">
                        <button class="prev">
                            <span></span>
                            <div class="title">
                                <div class="title2">{{ subnavPrev?.title }}</div>
                            </div>
                        </button>
                    </router-link>
                    <div class="current">
                        <div class="rte no-margin">
                            <div v-if="currentTitle" class="company-title">
                                <a v-if="currentLinkout" :href="currentLinkout" target="_blank">
                                    <h3>{{ currentTitle }}</h3>
                                </a>
                                <h4 v-else>{{ currentTitle }}</h4>
                            </div>

                        </div>
                    </div>
                    <router-link :to="subnavNext?.path">
                        <button class="next">
                            <span></span>
                            <div class="title">
                                <div class="title2">{{subnavNext?.title}}</div>
                            </div>
                        </button>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "SubnavComponent",
    props: {
        filterType: {
            type: String,
            required: true,
            default: '',
        },
        currentTitle: {
            type: String,
            required: true,
            default: '',
        },
        currentLinkout: {
            type: String,
            required: false,
            default: '',
        },
    },
    data () {
        return {
            window: window,
            subnavPrev:null,
            subnavNext:null,
        }
    },
    mounted() {
        let path = this.$route.fullPath
        const items = this.window.config.json.global.routes.filter(x => x.type === this.filterType)
        //const path = '/' + slug
        const index = items.map(x => x.path).indexOf(path)
        let indexPrev = index - 1
        if (indexPrev<0) indexPrev = items.length-1
        let indexNext = index + 1
        if (indexNext>=items.length) indexNext=0
        this.subnavPrev = items[indexPrev]
        this.subnavNext = items[indexNext]
    }
}
</script>
