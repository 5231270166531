<template>
    <div :class="['block block-generic-text', (bgColor) ? bgColor : '']" >
        <div class="block-generic-text2">
            <div class="rte">
                <FeaturedSlides v-if="bgContentArray" :smallMode="true" :bgContentArray="bgContentArray"></FeaturedSlides>
                <div class="inner" data-aos="custom-fade-in" >
                    <h2 v-if="json.title" v-html="json.title"></h2>
                    <div class="under-title" v-if="json.body" v-html="json.body"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import FeaturedSlides from "@/components/blocks/FeaturedSlides"
export default {
    name: "GenericText",
    data () {
        return {

        }
    },
    components: {
        FeaturedSlides
    },
    props: {
        json: {
            type: Object,
            required: true,
            default: null,
        },
        bgColor: {
            type: String,
            required: false,
            default: '',
        },
        bgContentArray: {
            type: Array,
            required: false,
            default: null,
        },
    },

}
</script>