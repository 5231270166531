<template>
    <div id="header-container" :class="`header-theme-${headerThemeComputed}`">
        <header v-if="json" :class="[`route-type-${routeType}`, (smallHeader) ? 'small' : '']">
            <div class="capper">
                <div class="capper-padded">
                    <div class="flex-content">
                        <div class="l">
                            <router-link
                                to="/"
                            >
                                <div class="svg-holder">
                                    <inline-svg
                                        class="tt-light"
                                        src="/assets/site/images/global/tt-white-name.svg"
                                        aria-label="InMaat Foundation"
                                    ></inline-svg>
                                    <inline-svg
                                        class="tt-dark"
                                        src="/assets/site/images/global/tt-dark-name.svg"
                                        aria-label="InMaat Foundation"
                                    ></inline-svg>
                                </div>
                                <div class="txt"><div class="txt2">Balance, Harmony and Justice</div></div>
                            </router-link>
                        </div>
                        <div class="c"></div>
                        <div class="r" v-show="json?.news?.length>0">
                            <div id="news" @click="setNewsNavOpen(true)">
                                <span class="roundy"><!-- campaign breaking_news newsmode -->
                                    <span class="txt2 material-symbols-outlined">favorite_border</span>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
        <div id="mobile-nav-close-hook" v-show="mobileNavOpen || newsNavOpen" @click="hamburgerClose"></div>
        <div id="mobile-nav-hamburger" :class="[`route-type-${routeType}`, (smallHeader) ? 'small' : '', (mobileNavOpen || newsNavOpen) ? 'is-active' : 'is-inactive']">
            <button :class="['hamburger hamburger--spin', (mobileNavOpen || newsNavOpen) ? 'is-active' : '']" @click="hamburgerMultiverseClicker" type="button">
                <span class="hamburger-box"><span class="hamburger-inner"></span></span>
            </button>
        </div>
        <div v-if="json"  id="mobile-nav-overlay-outer">
            <div id="mobile-nav-overlay" :class="(mobileNavOpen) ? '' : 'hidden'">
                <div class="inner">
                    <nav class="main-nav line">
                        <ul>
                            <li
                                v-for="(item, index) in json.header.nav"
                                :key="`mobilenav-${index}`"
                            >
                                <div @click="setMobileNavOpen(false)">
                                    <Link
                                        :href="item.href"
                                        :target="item.target"
                                        :label="item.label"
                                        classes="link-underline-leftright cyan"
                                    />
                                </div>
                            </li>
                        </ul>
                    </nav>
                    <div class="social line">
                        <Social></Social>
                    </div>
                    <div class="mailing-list line">
                        <EmailSignup
                            :mcDomain="json.mc.mcDomain"
                            :mcUserId="json.mc.mcUserId"
                            :mcListId="json.mc.mcListId"
                        ></EmailSignup>
                    </div>
                    <div class="legal line">
                        <div v-show="!isLegalVisible" @click="showLegal" class="show-legal">Legal +</div>
                        <div :class="['legal-zone', (isLegalVisible) ? 'open' : '']">
                            <div class="no-max-width">
                                <div class="legal-line">
                                    <p>{{json.footer.legal1}}</p>
                                </div>
                                <div class="legal-line">
                                    <nav class="footer-nav">
                                        <ul>
                                            <li
                                                v-for="(item, index) in json.footer.nav"
                                                :key="`footer-nav-${index}`"
                                            >
                                                <Link
                                                    :href="item.href"
                                                    :target="item.target"
                                                    :label="item.label"
                                                    classes="link-underline-leftright small"
                                                />
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="json && json?.news"  id="news-overlay-outer">
            <div id="news-overlay" :class="(newsNavOpen) ? '' : 'hidden'">
                <div class="inner">
                    <nav class="main-nav line">
                        <ul>
                            <li
                                v-for="(item, index) in json?.news"
                                :key="`mobilenav-${index}`"
                            >
                                <div @click="setNewsNavOpen(false)">
                                    <Link
                                        :href="item.href"
                                        :target="item.target"
                                        :label="item.title"
                                        classes="zlink-underline-leftright cyan"
                                    />
                                    <div class="txt date">{{item.articleDate}}</div>
                                </div>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
    </div>
</template>
<script>
//state
import { mapState } from 'vuex'
import Link from "@/components/pieces/Link";
import EmailSignup from '@/components/pieces/EmailSignup'
import Social from '@/components/pieces/Social'
export default {
    name: "HeaderComponent",
    components: {
        Link,
        EmailSignup,
        Social
    },
    data() {
        return {
            window:window,
            document:document,
            json:null,
            isSmallHeader:false,
            mobileNavOpen:false,
            newsNavOpen: false,
            routeType:'',
            isLegalVisible:true,
            scrollCheckElem:[],
            scrollCheckOverride:true,
            headerTheme:null,
            headerThemeComputedToggler:Math.random(),
        }
    },
    computed: {
        ...mapState({
            smallHeader: state => state.smallHeader,
            mobileCloseToggler: state => state.mobileCloseToggler,
        }),
        headerThemeComputed() {
            if(this.headerThemeComputedToggler && this.scrollCheckOverride) {
                return 'dark'
            } else{
                return this.headerTheme
            }
        }
    },
    watch: {
        smallHeader() {
            this.isSmallHeader = this.smallHeader
        },
        mobileCloseToggler() {
            this.setMobileNavOpen(false)
        },
        $route (to){
            this.routeType = to.meta.type
            this.headerTheme = to.meta.headerTheme

            //kill all route types from body.  add new one
            this.document.body.className = document.body.className
                .split(' ')
                .filter(className => !className.startsWith('route-type'))
                .join(' ')
            this.document.body.className += ' route-type-' + this.routeType

            this.scrollCheckElem = null
            this.scrollCheckOverride = false
            const t = this
            // just check it twice and that's it
            setTimeout(function() {
                t.scrollCheckElem = t.document.querySelector(".scroll-check-trigger")
                t.headerThemeComputedToggler = Math.random()
                if (!t.scrollCheckElem) {
                    setTimeout(function() {
                        t.scrollCheckElem = t.document.querySelector(".scroll-check-trigger")
                        t.headerThemeComputedToggler = Math.random()
                    }.bind(t),500)
                }
            }.bind(t),500)

        },
    },
    mounted() {
        this.json = this.window.config.json.global
        window.addEventListener('scroll', this.scroll)
        window.addEventListener('keyup', this.detectEscapeKey)
    },

    methods: {
        detectEscapeKey(e) {
            if (e.keyCode === 27) {
                this.setMobileNavOpen(false)
                this.setNewsNavOpen(false)
            }
        },
        scroll() {
            this.$store.commit('setSmallHeader',(window.scrollY>0))
            if (this.scrollCheckElem) {
                if (window.scrollY >= this.scrollCheckElem.offsetTop - 55) { //65 is a nice buffer
                    this.scrollCheckOverride = true
                } else {
                    this.scrollCheckOverride = false
                }
            }
        },
        setMobileNavToggle() {
            this.mobileNavOpen = !this.mobileNavOpen
            if (!this.mobileNavOpen) {
                this.hideLegal()
            }
        },
        setMobileNavOpen(isOpen) {
            this.mobileNavOpen=isOpen
            if (!this.mobileNavOpen) {
                this.hideLegal()
            }
        },
        showLegal() {
            this.isLegalVisible = true
        },
        hideLegal() {
            // this.isLegalVisible = false
        },
        setNewsNavOpen(isOpen) {
            this.newsNavOpen=isOpen
        },
        hamburgerClose() {
            this.setMobileNavOpen(false)
            this.setNewsNavOpen(false)
        },

        // close news nav - or toggle menu
        hamburgerMultiverseClicker() {
            if (this.newsNavOpen) {
                this.setNewsNavOpen(false)
            } else {
                this.setMobileNavToggle()
            }
        }
    }
}
</script>