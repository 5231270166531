<template>
    <div :class="[`block block-featured-slides`, (smallMode) ? 'small' : 'full thefold']" >
        <div v-if="bgContentArray" :id="swiperContainerId" class="swiper-container" >

            <div class="swiper-wrapper" >
                <div class="swiper-slide"
                     v-for="(item, index) in bgContentArray"
                     :key="`featured-${index}-${Math.random()}`"
                >
                    <div v-if="item.bgVideo" :class="(smallMode) ? 'bg' : 'bgimg'">
                        <video preload="metadata" :class="`video-${index}`" autoplay muted loop :poster="item.bgPoster" webkit-playsinline="true" playsinline="true">
                            <source :src="item.bgVideo" type="video/mp4">
                        </video>
                        <div v-if="item.imageAttribution" class="image-attribution">{{item.imageAttribution}}</div>
                    </div>
                    <div v-else-if="smallMode" :class="(smallMode) ? 'bg' : 'bgimg'" :style="item.bgWithStyle">
                        <img :src="item.bgPoster"/>
                        <div v-if="item.imageAttribution" class="image-attribution">{{item.imageAttribution}}</div>
                    </div>
                    <div v-else :class="(smallMode) ? 'bg' : 'bgimg'" :style="item.bgWithStyle">
                        <div v-if="item.imageAttribution" class="image-attribution">{{item.imageAttribution}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper, {Navigation, Pagination, Autoplay, EffectFade} from 'swiper'
import 'swiper/swiper-bundle.min.css'

Swiper.use([Navigation, Pagination, Autoplay, EffectFade])//EffectCube
//import gsap, {Power3} from 'gsap'

export default {
    name: "FeaturedSlides",
    components:{

    },
    data () {
        return {
            document:document,
            slider: null,
            swiperContainerId: null,
            swiperContainerSel: null
        }
    },
    props: {
        bgContentArray: {
            type: Array,
            required: true,
            default: null,
        },
        smallMode: {
            type: Boolean,
            required: false,
            default: false,
        }
    },
    mounted () {
        this.swiperContainerId = 'fs-' + Date.now()
        this.swiperContainerSel = '#' + this.swiperContainerId
        this.$nextTick(() => {
            this.init()
        });
    },
    methods: {
        init () {
            this.slider = new Swiper(this.swiperContainerSel, {
                init: false,
                speed: 750,

                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                /*
                effect: 'cube',
                cubeEffect: {
                    slideShadows: false,
                },
                 */
                grabCursor: true,
                centeredSlides: true,
                loop: (this.bgContentArray.length>1),
                allowSlidePrev: (this.bgContentArray.length>1),
                allowSlideNext: (this.bgContentArray.length>1),
                simulateTouch: (this.bgContentArray.length>1),
                slidesPerView: 1,
                preventClicks: false,
                preventClicksPropagation: false,
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true,
                },

                autoplay: {
                    delay: 4000,
                },
            })

            let t = this
            this.slider.on('slideChangeTransitionEnd', function (e) {
                let id = 'video-' + e.realIndex
                t.playVids(id)
            }.bind(t))
            this.slider.on('afterInit', function () {
                let id = 'video-0'
                t.playVids(id)
            }.bind(t))

            setTimeout(this.sliderInit2, 100)
        },
        sliderInit2 () {
            this.slider.init()
        },
        playVids(id) {
            let vid = document.getElementsByClassName(id)
            for (let i = 0; i < vid.length; i++) {
                try {
                    vid[i].muted = true
                    vid[i].volume = 0
                    vid[i].play()
                } catch (error) {
                    console.error('e',error)
                }
            }
        }
    }
}
</script>
