<template>
    <section v-if="json" class="page-resources-detail thefold">
        <div class="featured">
            <div class="bgimg" :style="json.bgWithStyle">
                <div v-if="json.imageAttribution" class="image-attribution">{{json.imageAttribution}}</div>
            </div>
            <div :class="`scrim abs100 header-theme-${headerTheme}`"></div>
            <!--
            <div v-if="json.title" class="main-title" >
                <div class="rte light">
                    <h1>{{json.title}}</h1>
                </div>
            </div>
            -->
            <div v-if="json.caption" class="caption">
                <div class="capper">
                    <div class="capper-padded-2x">
                        <div class="inner">
                            <div class="rte" >
                                <div  v-html="json.caption"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Subnav
            class="scroll-check-trigger"
            filterType="resources-detail"
            :currentTitle="json.title"
            currentLinkout=""
        ></Subnav>

        <div v-if="json.content" class="links">
            <div class="capper">
                <div class="capper-padded-2x">
                    <div :class="['link',(item.section) ? 'link-title' : 'link-body']" v-for="(item, index) in json.content" :key="`link-${index}-${Math.random()}`" >
                        <div class="section-title" v-if="item.section" data-aos="custom-fade-in">
                            <div class="rte no-max-width">
                                <h4>{{item.section}}</h4>
                            </div>
                        </div>
                        <div v-else class="section-body" >
                            <div  class="link-title rte no-max-width">
                                <!--
                                <div v-if="item.date || item.source" data-aos="custom-fade-in" class="meta">
                                    <p><span class="date" v-html="item.date"></span><span class="source" v-html="item.source"></span></p>
                                </div>
                                -->
                                <div data-aos="custom-fade-in" :class="['icon', 'icon-'+item.type]">
                                    <inline-svg :src="getImgUrl(item.type)"></inline-svg>
                                </div>
                                <div data-aos="custom-fade-in" class="title"><h6 v-html="item.title"></h6></div>
                            </div>
                            <div class="link-cols">
                                <div class="link-l">
                                    <div class="rte no-max-width">
                                        <div v-if="item.description" data-aos="custom-fade-in" class="description" v-html="item.description"></div>
                                    </div>
                                </div>
                                <div class="link-r">
                                    <div data-aos="custom-fade-in" v-if="item.linkType==='internal'" class="btn-holder-main">
                                        <router-link :to="item.link">
                                            <div class="btn-holder">
                                                <div class="btn">
                                                    <span>{{getButtonLabel(item.type)}}</span>
                                                </div>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div data-aos="custom-fade-in" v-else class="btn-holder-main">
                                        <a :href="item.link" target="_blank">
                                            <div class="btn-holder">
                                                <div class="btn">
                                                    <span>{{getButtonLabel(item.type)}}</span>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
import gsap, {Power3, Linear} from 'gsap'
import { SplitText} from 'gsap/SplitText'
import { mapState } from 'vuex'
//import _ from 'lodash'
import Subnav from "@/components/blocks/Subnav"
gsap.registerPlugin(SplitText)
export default {
    name: "ResourcesDetail",
    components: {
        Subnav
    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
            visionaryPrev: null,
            visionaryNext: null,
            headerTheme:null
        }

    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition
        }),
    },
    mounted () {
        this.headerTheme = this.$route.meta.headerTheme

        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        slug = slug.replace('inspiration/','')
        let slugHook = (window.config.apiext.charAt(0) === '?') ? '&' : '?'
        axios.get((`${window.config.apiroot}resources-detail${window.config.apiext}${slugHook}slug=${slug}`))
            .then((res) => {
                this.json = res.data
                //this.json.content = _.sortBy(this.json.content, ['section','sort']);

                if (this.didInitialSiteTransition) {
                    if (!this.inPanelTransition) {
                        setTimeout(this.reveal,10)
                    }
                }
            }).catch((e) => {
            console.error('Error', this.slug, e)
        })
    },
    watch: {
        didInitialSiteTransition() {
            if (!this.inPanelTransition) {
                this.reveal()
            }

        }
    },
    methods: {

        reveal () {
            new SplitText('.page-resources-detail .caption p', {
                type: 'words, chars',
                charsClass: 'txt-split'
            });

            let tl = gsap.timeline({});

            tl.fromTo('.page-resources-detail .bgimg',
                {
                    opacity:0,
                    filter: 'grayscale(1)'

                },
                {
                    opacity:1,
                    filter: 'grayscale(0)',
                    duration:2,
                    ease:Linear.easeNone,
                }
            )
            tl.fromTo('.page-resources-detail .caption',
                {
                    scaleX:0
                },
                {
                    scaleX:1,
                    duration:1,
                    ease:Power3.easeInOut,
                },
                0.5
            )
            tl.from('.page-resources-detail .caption .txt-split',
                {
                    duration: 0.7,
                    opacity: 0,
                    ease: Power3.easeOut,
                    stagger: 0.015,
                },
                1.2
            )

        },
        unreveal () {

        },
        getImgUrl (type) {
            return require(`@/assets/images/global/resources-${type}.svg`)
        },
        getButtonLabel (type) {
            let ret = 'Learn More'
            switch (type) {
                case "article":
                    ret = 'Read Now'
                    break
                case "audio":
                    ret = 'Listen Now'
                    break
                case "video":
                    ret = 'Watch Now'
                    break
            }
            return ret
        },

    }
}
</script>
