<template>
<div class="form-callout">
    <div class="capper">
        <div class="capper-padded-2x">
            <div class="rte no-max-width">
                <div class="shaded" :data-aos="aosProperty" >
                    <div class="description" v-html="json.description"></div>
                    <div class="btn-holder-outer">
                        <a :href="json.link" target="_blank">
                            <div class="btn-holder">
                                <div class="btn">
                                    <span>{{ json.buttonLabel }}</span>
                                </div>
                            </div>
                        </a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: "FormCallout",
    props: {
        json: {
            type: Object,
            required: true
        },
        useAos: {
            type: Boolean,
            required: false,
            default: true
        },
    },
    computed: {
        aosProperty () {
            return (this.useAos) ? 'custom-fade-in' : ''
        }
    }
}
</script>
