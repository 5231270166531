<template>
    <div class="blue-center-logo thefold">
        <div class="flex-center">
            <div class="flex-inner" >
                <div class="logo-holder">
                    <div class="logo-holder2">
                        <router-link to="/what-we-believe">
                        <img src="/assets/site/images/global/tt-blue.png">
                        </router-link>
                        <div v-if="!nameRight" class="hidden-name abs100">
                            <img src="/assets/site/images/global/tt-blue-name.png">
                        </div>
                        <div v-else class="name-right abs100">
                            <div class="name-right2">
                                <img src="/assets/site/images/global/tt-just-name.svg">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlueCenterLogo",
    props: {
        nameRight: {
            type: Boolean,
            required: false,
            default: false
        },
    }
}
</script>
