<template>
    <div id="container">
        <Header/>
        <div id="main">
            <router-view :key="$route.fullPath"></router-view>
        </div>
        <Popup/>
        <DownArrow/>
        <TransitionOverlay></TransitionOverlay>
        <RotateBlocker v-show="showBlocker"></RotateBlocker>
    </div>
</template>

<script>
import Header from './components/framework/Header'
//import Footer from './components/framework/Footer'
import Popup from './components/framework/Popup'
import DownArrow from './components/framework/DownArrow'
import TransitionOverlay from './components/framework/TransitionOverlay'
import RotateBlocker from './components/framework/RotateBlocker'
import { isMobile, browserName } from 'mobile-device-detect'
// import { detect } from 'detect-browser'
export default {
    name: 'App',
    components: {
        Header,
        //Footer,
        DownArrow,
        Popup,
        TransitionOverlay,
        RotateBlocker
    },
    data() {
        return {
            window:window,
            document:document,
            showBlocker:false,
        }
    },
    mounted() {
        //console.clear()
        let t = "%c ✨ We Can Be Heroes - https://heroic.art ✨"
        let n = ["font-size: 12px", "color: #fffce1", "font-family: monospace", "background: #0e100f", "display: inline-block", "padding: 1rem 3rem", "border: 1px solid #fffce1", "border-radius: 4px;"].join(";");
        console.log(t, n)

        // https://www.npmjs.com/package/mobile-device-detect
        let bname = String(browserName).toLowerCase()
        bname = bname.replace('mobile', '')
        bname = bname.replace(' ', '')
        this.document.body.className += ' browser-'+ bname
        if (isMobile) {
            this.document.body.className += ' device-mobile'
        } else {
            this.document.body.className += ' device-notmobile'
        }


        this.window.addEventListener('resize', this.resize)
        this.resize()
        if (isMobile) {
            /*
            this.window.addEventListener('orientationchange', this.siteOrientationChange)
            this.siteOrientationChange()
             */
        }
    },
    methods: {
        resize () {
            // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
            let vh = this.window.innerHeight * 0.01;
            this.document.documentElement.style.setProperty('--vh', `${vh}px`)

            //this.siteOrientationChange()
        },
        /*
        siteOrientationChange() {
            if (isMobile) {
                let t = this
                setTimeout(function () {
                    t.showBlocker = (t.window.innerWidth > t.window.innerHeight)
                    //if( (window.orientation === 90 && $( window ).width() < 780) || (window.orientation === -90 && $( window ).width() < 780) ) // Landscape {
                }.bind(t), 100)
            }
        },

         */
    }
}
</script>
<style lang="scss">
@import "./assets/scss/app.scss";
</style>
