<template>
    <section v-if="json" class="page-resources thefold">
        <div class="capper">
            <div class="top" data-header-bg="light" data-aos="custom-fade-in" >
                <div class="capper-padded-2x">
                    <div class="rte">
                        <h2 v-html="json.title"></h2>
                        <div class="description" v-html="json.description"></div>
                    </div>
                </div>
            </div>
            <div class="grid-wrapper" >
                <div class="capper-padded-2x">
                    <div class="grid">
                        <div v-for="(item, index) in json.content" :key="`entry-${index}`" class="col" data-aos="custom-fade-in">
                            <router-link class="generic-image-hover" :to="item.link">
                                <div class="poster" >
                                    <div class="bgimg" :style="item.bgWithStyle"></div>
                                    <div class="cover"></div>
                                    <div class="txt" :class="(item.bgWithStyle) ? 'has-image' : 'no-image'">
                                        <div class="rte light inner"><p>{{item.title}}</p></div>
                                    </div>

                                    <div class="poster-title-mobile" >
                                        <div class="rte light">
                                            <div class="inner">
                                                <p>{{item.title}}</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>

            <FormCallout v-if="json.formCallout" :json="json.formCallout"></FormCallout>

        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
import FormCallout from "@/components/pieces/FormCallout"
export default {
    name: "ResourcesPage",
    components: {
        FormCallout
    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
        }

    },
    created () {
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        axios.get((`${window.config.apiroot}resources${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                this.reveal()
            }).catch((e) => {
                console.error('Error',slug,e)
        })
    },
    methods: {
        reveal () {

        },
        unreveal () {

        },
    }
}
</script>
