<template>
    <section v-if="json" class="page-visionaries-detail" Zdata-aos="custom-fade-in">
        <div  v-for="(item, index) in json.content" :key="'section-blocks'+index" class="section-blocks" :class="'section-blocks-index'+index">
            <FeaturedSlides v-if="item.type === 'featuredImages'" :bgContentArray="item.content"></FeaturedSlides>
            <div v-if="item.type === 'statisticsWithImages'" class="statistics-with-images capper">
                <div class="blocks">
                    <div data-aos="custom-fade-in" class="block" v-for="(item2, index2) in item.content" :key="'block'+index+'-'+index2">
                        <div v-if="item2.bgWithStyle" class="bgimg" :style="item2.bgWithStyle" >
                            <div v-if="item2.imageAttribution" class="image-attribution">{{item2.imageAttribution}}</div>
                        </div>
                        <div class="inner" >
                            <div class="rte">
                                <div v-if="item2.title" class="title"  ><h3>{{item2.title}}</h3></div>
                                <div v-if="item2.description" class="description" v-html="item2.description"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.type === 'statistics'" class="statistics">
                <div class="capper">
                    <div class="capper-padded-2x">
                        <div class="blocks">
                            <div data-aos="custom-fade-in" class="block" v-for="(item2, index2) in item.content" :key="'block'+index+'-'+index2">
                                <div class="inner">
                                    <div class="rte">
                                        <div v-if="item2.title" class="title"  ><h3>{{item2.title}}</h3></div>
                                        <div v-if="item2.description" class="description" v-html="item2.description"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.type === 'quote'" class="quote">
                <div class="capper">
                    <div class="capper-padded-2x">
                        <div class="inner">
                            <h2 data-aos="custom-fade-in" v-html='`“${item.quote}”`'></h2>
                            <p class="attribute" v-if="item.attribute" data-aos="custom-fade-in" v-html='`&mdash;${item.attribute}`'></p>
                            <div class="generic-line" data-aos="custom-scale-from-center"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="item.type === 'text'" class="text capper">
                <div class="capper">
                    <div class="capper-padded-2x">
                        <div data-aos="custom-fade-in" class="rte" v-html="genericTextWrapper(item.text)"></div>
                    </div>
                </div>
            </div>

            <!--always show second -->
            <div v-if="index === 0" class="capper scroll-check-trigger">
                <div class="visionaries-logline-nav">
                    <Subnav
                        filterType="visionaries-detail"
                        :currentTitle="json.title"
                        :currentLinkout="json.website"
                    ></Subnav>
                    <div v-if="json.logline" class="group visionaries-logline" data-aos="custom-fade-in">
                        <div class="capper-padded-2x">
                            <div class="rte">
                                <h5 v-html="json.logline"></h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="final-link" data-aos="custom-fade-in">
            <div class="capper">
                <div class="capper-padded-2x">
                    <div class="rte">
                        <p><a :href="json.website" target="_blank">{{json.website}}</a></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
import FeaturedSlides from "@/components/blocks/FeaturedSlides"
import Subnav from "@/components/blocks/Subnav"
import gsap, {Linear} from "gsap";
export default {
    name: "VisionariesDetail",
    components: {
        FeaturedSlides,
        Subnav
    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
        }

    },

    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        slug = slug.replace('who-we-support/','')
        let slugHook = (window.config.apiext.charAt(0) === '?') ? '&' : '?'
        axios.get((`${window.config.apiroot}visionaries-detail${window.config.apiext}${slugHook}slug=${slug}`))
            .then((res) => {
                this.json = res.data
                console.log('this.json', this.json)
                this.reveal()
            }).catch((e) => {
                console.error('Error',slug,e)
        })
    },
    computed: {


    },
    methods: {
        reveal () {
            let tl = gsap.timeline({
                delay:0
            });
            tl.fromTo('.page-visionaries-detail',
                {
                    opacity: 0
                },
                {
                    opacity: 1,
                    ease: Linear.easeNone,
                    duration: 0.7,
                },
                0
            )
        },
        unreveal () {

        },
        genericTextWrapper(html) {
            return gh.genericTextWrapper(html)
        }
    }
}
</script>
