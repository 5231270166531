<template>
    <section v-if="json" class="page-news-detail">
        <GenericText
            bgColor="bg-purple-dk"
            :json="json"
            :bgContentArray="json.backgrounds"
        ></GenericText>
    </section>
</template>
<script>
import axios from 'axios'
import GenericText from '@/components/blocks/GenericText'
import gh from '@/helpers/gh'
export default {
    name: "NewsDetail",
    components: {
        GenericText
    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
        }

    },
    created () {
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        slug = slug.replace('news/','')
        let slugHook = (window.config.apiext.charAt(0) === '?') ? '&' : '?'
        axios.get((`${window.config.apiroot}news-detail${window.config.apiext}${slugHook}slug=${slug}`))
            .then((res) => {
                this.json = res.data
                console.log('this.json', this.json)
                this.reveal()
            }).catch((e) => {
                console.error('Error',slug,e)
        })
    },
    methods: {
        reveal () {

        },
        unreveal () {

        },
    }
}
</script>
