<template>
    <section v-if="json" class="page-mission" >
        <FeaturedSlides :bgContentArray="json.mission.backgrounds"></FeaturedSlides>
        <div class="top flex-bottom vh" data-header-bg="dark" >
            <div class="scrim"></div>
            <div class="flex-inner" zdata-aos="custom-fade-in" :class="(inPanelTransition) ? 'opacity1' : 'opacity0'">
                <div class="capper">
                    <div class="capper-padded-2x">
                        <div class="copy-wider">
                            <div class="rte light">
                                <h1>{{ json.mission.title }}</h1>
                                <div class="copy">
                                    <p>{{ json.mission.subtitle }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="json.vision" class="vision" data-header-bg="light" data-aos="custom-fade-in">
            <div class="capper">
                <div class="capper-padded-2x">
                    <div class="rte light">
                        <h1>{{ json.vision.title }}</h1>
                        <div class="copy" v-html="json.vision.subtitle"></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="json.principals" class="principals" data-header-bg="light">
            <div class="capper">
                <div class="capper-padded-2x">
                    <div class="rte light">
                        <h1 data-aos="custom-fade-in">{{ json.principals.title }}</h1>
                        <div class="description" v-html="json.principals.description"></div>

                        <div v-for="(item, index) in json.principals.entries" :key="`entry-${index}`" class="entry">
                            <div class="line">
                                <div data-aos="custom-width-100" class="line2"></div>
                            </div>
                            <div class="txt" data-aos="custom-fade-in" v-html="item"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="json.approach" class="approach" data-header-bg="light" data-aos="custom-fade-in">
            <div class="capper">
                <div class="capper-padded-2x">
                    <div class="rte light">
                        <div class="title"><h1 v-html="json.approach.title"></h1></div>
                        <div class="description" v-html="json.approach.description"></div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
import axios from 'axios'
import gh from '@/helpers/gh'
import gsap, {Power3} from 'gsap'
import {SplitText} from 'gsap/SplitText'
import {mapState} from 'vuex'
import FeaturedSlides from "@/components/blocks/FeaturedSlides";
//import BlueCenterLogo from '@/components/pieces/BlueCenterLogo'
gsap.registerPlugin(SplitText)
export default {
    name: "MissionPage",
    components: {
        //BlueCenterLogo,
        FeaturedSlides
    },
    data () {
        return {
            window: window,
            json: null,
            transition: false,
            inPanelTransition: false,
        }

    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition
        }),
    },
    mounted () {
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        axios.get((`${window.config.apiroot}mission${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                if (this.didInitialSiteTransition) {
                    if (!this.inPanelTransition) {
                        setTimeout(this.reveal, 10)
                    }
                }
            }).catch((e) => {
            console.error('Error', slug, e)
        })
    },
    watch: {
        didInitialSiteTransition () {
            if (!this.inPanelTransition) {
                this.reveal()
            }
        }
    },
    methods: {
        reveal () {
            if (!this.inPanelTransition) {
                this.inPanelTransition = true

                /*
                new SplitText('.page-mission .top .rte h1', {
                    type: 'words, chars',
                    charsClass: 'txt-split'
                });

                 */
                new SplitText('.page-mission .top .rte p', {
                    type: 'words, chars',
                    charsClass: 'txt-split'
                });

                let tl = gsap.timeline({
                    delay:0
                });
                tl.from('.page-mission .top .rte h1',
                    {
                        duration: 0.7,
                        opacity: 0,
                        ease: Power3.easeOut
                    },
                    0.75
                )
                tl.from('.page-mission .txt-split',
                    {
                        duration: 0.7,
                        opacity: 0,
                        ease: Power3.easeOut,
                        stagger: 0.01,
                    },
                    1
                )
            }

        },
        unreveal () {

        },
    }
}
</script>
