<template>
    <section v-if="json" class="page-contact" :class="(inPanelTransition) ? 'opacity1' : 'opacity0'" >
        <div class="social">
            <div class="social2">
                <div class="capper">
                    <div class="rte light">
                        <h1 v-html="$route.meta.title"></h1>
                    </div>
                    <Social
                        themeColor="white"
                    ></Social>
                </div>
            </div>
        </div>
        <div class="contact">
            <div class="contact2">
                <div class="capper">
                    <div class="capper-padded">
                        <div class="items">
                            <!--<span class="item material-icons-outlined">location_on</span>-->

                            <div class="item email">
                                <div class="rte no-a">
                                    <h5>
                                        Email us:
                                    </h5>
                                    <p>
                                        <a class="link-underline-leftright-whitesmall" :href="'mailto:' + jsonGlobal.contact.email">{{jsonGlobal.contact.email}}</a>
                                    </p>
                                </div>
                            </div>

                            <div class="item signup">
                                <div class="label rte">
                                    <h5>Sign up for updates:</h5>
                                </div>
                                <EmailSignup
                                    :mcDomain="jsonGlobal.mc.mcDomain"
                                    :mcUserId="jsonGlobal.mc.mcUserId"
                                    :mcListId="jsonGlobal.mc.mcListId"
                                ></EmailSignup>
                            </div>
                            <div class="item email">
                                <div class="rte no-a">
                                    <h5>
                                        Press Kit / Logos:
                                    </h5>
                                    <p>
                                        <a class="link-underline-leftright-whitesmall" target="_blank" href="https://docs.google.com/document/d/1eJ8AIt4ktLj8idej0BIolJnYw5MvglJzdkbgMDT9CI0/edit">click to open</a>
                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <FormCallout class="item" v-if="json.formCallout" :json="json.formCallout" :useAos="false"></FormCallout>

        <div class="team" data-header-bg="light">
            <div class="capper">
                <div class="capper-padded-2x">
                    <div class="rte light">
                        <h1 data-aos="custom-fade-in">{{ json.team.title }}</h1>
                        <div v-for="(item, index) in json.team.entries" :key="`teamentry-${index}`" class="entry">
                            <div class="photo" data-aos="custom-scale-in">
                                <div class="bgimg" :style="item.bgWithStyle"></div>
                            </div>
                            <div class="txt" data-aos="custom-fade-in">
                                <h5 class="name">{{ item.name }}</h5>
                                <p class="title">{{ item.title }}</p>
                                <div class="bio" v-html="item.bio"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>
</template>
<script>
import axios from 'axios'
import Social from '@/components/pieces/Social'
import EmailSignup from '@/components/pieces/EmailSignup'
import gsap, {Linear} from 'gsap'
import gh from '@/helpers/gh'
//import { SplitText} from 'gsap/SplitText'
import { mapState } from 'vuex'
import FormCallout from "@/components/pieces/FormCallout"
export default {
    name: "ContactPage",
    components: {
        Social,
        EmailSignup,
        FormCallout
    },
    data () {
        return {
            window: window,
            json: null,
            jsonGlobal: null,
            inPanelTransition: false,
        }
    },
    computed: {
        ...mapState({
            didInitialSiteTransition: state => state.didInitialSiteTransition
        }),
    },

    mounted () {
        this.jsonGlobal = this.window.config.json.global
        let slug = this.$route.fullPath
        slug = gh.stripSlashesFrontAndBack(slug)
        axios.get((`${window.config.apiroot}contact${window.config.apiext}`))
            .then((res) => {
                this.json = res.data
                if (this.didInitialSiteTransition) {
                    if (!this.inPanelTransition) {
                        setTimeout(this.reveal, 10)
                    }
                }
            }).catch((e) => {
            console.error('Error', slug, e)
        })
    },
    watch: {
        didInitialSiteTransition() {
            if (!this.inPanelTransition) {
                this.reveal()
            }
        }
    },
    methods: {
        reveal() {
            this.inPanelTransition = true
            let tl = gsap.timeline({});
            tl.from('.page-contact .social-links .socicon-holder',
                {
                    opacity: 0,
                    duration:1,
                    ease: Linear.easeNone,
                    stagger: 0.1,
                },
                0
            )
            tl.from('.page-contact .item',
                {
                    opacity: 0,
                    duration:1,
                    ease: Linear.easeNone,
                    stagger: 0.1,
                },
                0.25
            )
        }

    }
}
</script>
