<template>
    <div v-show="show" id="transition-overlay-outer">
        <div id="transition-overlay" :class="(didOnce) ? 'did-once' : ''">
            <div id="transition-overlay-image" >
                <BlueCenterLogo></BlueCenterLogo>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import gsap, { Power1, Power3, Linear } from 'gsap'
    import gh from '@/helpers/gh'
    import { SplitText} from 'gsap/SplitText'
    import BlueCenterLogo from '@/components/pieces/BlueCenterLogo'
    gsap.registerPlugin(SplitText)
    export default {
        name: "TransitionOverlay",
        components: {
            BlueCenterLogo
        },
        data() {
            return {
                window:window,
                show:true, //start showing
                tme:0.75,
                didOnce:false,
            }
        },
        computed: {
            ...mapState({
                triggerTransitionOverlay: state => state.triggerTransitionOverlay,
                routerNext: state => state.routerNext,
            }),
        },
        watch: {
            triggerTransitionOverlay() {
                if (this.triggerTransitionOverlay) {
                    let doSkip = false //debugging
                    if (!this.didOnce && this.window.config.localMode && doSkip) {
                        //local first page build skip transition
                        this.routerNext()
                        this.reveal4()
                    } else {
                        //normal reveal for all
                        this.reveal()
                    }

                }
            }
        },
        methods: {
            isHomePage() {
                return this.$route.path === '/';
            },
            reveal() {
                if (this.isHomePage() && this.didOnce  === false) {
                    this.show = false
                    this.routerNext()
                    if (this.didOnce === false) {
                        setTimeout(this.reveal3,1500   )
                    }
                    return;
                }

                this.show = true
                let tl = gsap.timeline({});
                let imageTime = this.tme*1
                let nextTime = 500
                if (this.didOnce) {
                    tl.fromTo('#transition-overlay', this.tme / 4,
                        { opacity:0 },
                        { opacity:1, ease: Power3.easeInOut },
                        0
                    )
                } else {
                    //first time
                    this.routerNext()
                    tl.fromTo('#transition-overlay', 0,
                        { opacity:1 },
                        { opacity:1, ease: Power3.easeInOut },
                        0
                    )
                    //imageTime = this.tme*1
                    //nextTime = 750
                }

                tl.fromTo('#transition-overlay-image',imageTime,
                    {
                        scale:0.35,
                        opacity:0,
                        "clip-path": "circle(0% at 50% 50%)"
                    },
                    {
                        scale:1.0,
                        opacity:0.75,
                        "clip-path": "circle(20% at 50% 50%)",
                        ease:Power1.easeOut
                    },
                    0
                )
                setTimeout(this.reveal2,nextTime   )
            },
            reveal2() {
                if (this.didOnce) {
                    this.routerNext()
                }
                setTimeout(this.reveal3,250   )
            },
            reveal3() {
                let tl = gsap.timeline({});

                const element = document.querySelector('.page-home')
                if (element) {
                    tl.fromTo('.page-home .blue-center-logo .name-right',
                        {
                            opacity: 0,
                        },
                        {
                            duration: 1.0,
                            opacity: 1,
                            ease: Linear.easeNone,
                        },
                        0
                    )
                }

                tl.fromTo('#transition-overlay', this.tme/2,
                    { opacity: 1 },
                    { opacity: 0, ease: Linear.easeNone,onComplete:this.reveal4 },
                    0
                )
                this.firstHeaderAnimation(0.25)
            },
            firstHeaderAnimation(del=0) {
                //FIRST HEADER ANIMATION
                if (this.didOnce === false) {
                    new SplitText('#header-container .l .txt2', {
                        type: 'words, chars',
                        charsClass: 'txt-split'
                    })
                    let tl = gsap.timeline({
                        delay:del
                    });
                    tl.set('#header-container .l .txt2',{
                        opacity:1
                    })
                    tl.set('#header-container .r',{
                        opacity:1,
                        delay:del+0.2
                    })
                    tl.to("#mobile-nav-hamburger",
                        {
                            duration: 1,
                            ease: Linear.easeNone,
                            opacity:1,
                        },
                        0
                    )
                    tl.to("#header-container .l .svg-holder",
                        {
                            duration: 4,
                            ease: Power3.easeOut,
                            "clip-path": "circle(100% at 50% 50%)"
                        },
                        0
                    )
                    tl.fromTo('#header-container .l .txt-split',
                        {
                            opacity: 0,
                        },
                        {
                            duration: 0.7,
                            opacity: 1,
                            ease: Power1.easeOut,
                            stagger: 0.015
                        },
                        0.5
                    )
                }

                // trigger this earlier for home animation
                this.$store.commit('setDidInitialSiteTransition')

                //final - needs to be double set because sometimes this is called out of order
                this.didOnce = true


            },
            reveal4() {
                this.show = false

                this.window.config.routerInTransition = false
                if (!this.didOnce) {
                    this.$store.commit('setDidInitialSiteTransition')
                }

                gh.globalSectionInit()

                this.firstHeaderAnimation()

                //final
                this.didOnce = true
            }
        }
    }
</script>
