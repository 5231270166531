<template>
    <div id="rotate-blocker" class="abs100">
        <div class="rotate abs100">
            <div class="inner">
                <span class="material-icons-outlined">screen_lock_rotation</span>
                <br>
                <!--<img src="/assets/site/images/global/tt-blue-name.png">-->
                <div class="rte">
                    <p>Please rotate your screen</p>
                </div>
                <br>
                <img src="/assets/site/images/global/tt-just-name.svg">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "RotateBlocker",
}
</script>
